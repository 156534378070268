import { css } from '@emotion/css';
import getIcon from '../assets/get-icon';
// @ts-ignore
import styles from './index.module.less';
import { APPLIANCE_OPTIONS, TRACTION_OPTIONS, judePos } from '../utils';

const useStyles = ({
  margin,
  quadrant,
  widthArr,
}: {
  margin: number;
  quadrant: string;
  widthArr: number[];
}) => {
  const appliance4width =
    quadrant === 'left' ? widthArr[1] + widthArr[2] : widthArr[1] + widthArr[0];
  return {
    mid2: css`
      left: calc(50% - 15px);
    `,
    top2: css`
      top: ${(98 - 30) / 2 - margin}px;
    `,
    bottom2: css`
      bottom: ${(98 - 30) / 2 - margin}px;
    `,
    width4: css`
      width: ${appliance4width}px !important;
      height: unset !important;
    `,
    top4: css`
      top: ${(98 - appliance4width) / 2 - margin}px;
    `,
    bottom4: css`
      bottom: ${(98 - appliance4width) / 2 - margin}px;
    `,
  };
};

export default (props: any) => {
  const { toothInfo, type, gdt } = props;
  const { lost, appliance, traction } = toothInfo;
  const { qs }: any = getIcon(type, gdt);
  return (
    <>
      {lost && <img src={qs} height={15} width={15} />}
      {appliance !== '0' && <ApplianceIcon {...props} />}
      {traction?.length > 0 && <TractionIcon {...props} />}
    </>
  );
};
const ApplianceIcon = ({
  cureType,
  upper,
  side,
  index,
  toothInfo,
  widthArr,
  margin,
  type: featuresType,
  gdt,
}: any) => {
  const { jy, qd, ylj, yhdb, hd, qyk, cbg, ylk, qyg }: any = getIcon(
    featuresType,
    gdt
  );
  const { appliance } = toothInfo;
  const judeNum = cureType === 'C' ? 4 : 7;
  const quadrant = index <= judeNum ? 'left' : 'right';
  const antStyles = useStyles({ margin, quadrant, widthArr });
  const APPLIANCE_DICT: Record<string, string> = {};
  APPLIANCE_OPTIONS.forEach(({ label, value }: any) => {
    APPLIANCE_DICT[value] = label;
  });
  const typename = APPLIANCE_DICT[appliance?.type];
  const iconclassName = judePos({
    upper,
    side,
    typename,
    mesiodistalDirection: appliance?.mesiodistalDirection,
    direction: appliance?.direction,
    quadrant,
    styles,
    antStyles,
  });
  return (
    <>
      {typename === '压力脊' && <img src={ylj} className={iconclassName} />}
      {typename === '颌垫' && <img src={hd} className={iconclassName} />}
      {typename === '假牙' && <img src={jy} className={iconclassName} />}
      {typename === '前导' && <img src={qd} className={iconclassName} />}
      {typename === '咬合导板' && <img src={yhdb} className={iconclassName} />}
      {typename === '牵引钩' && <img src={qyg} className={iconclassName} />}
      {typename === '牵引扣' && <img src={qyk} className={iconclassName} />}
      {typename === '引力扣' && <img src={ylk} className={iconclassName} />}
      {typename === '长臂钩附件' && <img src={cbg} className={iconclassName} />}
    </>
  );
};
const TractionIcon = ({
  cureType,
  upper,
  side,
  index,
  toothInfo,
  type: featuresType,
  gdt,
}: any) => {
  const { traction } = toothInfo;
  const TRACTION_DICT: Record<string, string> = {};
  TRACTION_OPTIONS.forEach(({ label, value }: any) => {
    TRACTION_DICT[value] = label;
  });
  const Icon = ({ item, gdt }: any) => {
    const judeNum = cureType === 'C' ? 4 : 7;
    const quadrant = index <= judeNum ? 'left' : 'right';
    const typename = TRACTION_DICT[item?.type];
    const iconclassName = judePos({
      upper,
      side,
      typename,
      mesiodistalDirection: item?.mesiodistalDirection,
      direction: item?.direction,
      quadrant,
      styles,
    });
    const { ylk, qyk, qyg, cbg }: any = getIcon(featuresType, gdt);
    return (
      <>
        {typename === '牵引钩' && <img src={qyg} className={iconclassName} />}
        {typename === '牵引扣' && <img src={qyk} className={iconclassName} />}
        {typename === '引力扣' && <img src={ylk} className={iconclassName} />}
        {typename === '长臂钩附件' && (
          <img src={cbg} className={iconclassName} />
        )}
      </>
    );
  };
  return (
    <>
      {traction?.map((item: any, tractionIndex: number) => {
        return <Icon key={tractionIndex} item={item} gdt={gdt} />;
      })}
    </>
  );
};
