// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { HelmetProvider } from '/root/workspace/aismile-compatible_zTYF/node_modules/.pnpm/@umijs/renderer-react@4.4.6_react-dom@18.3.1+react@18.3.1/node_modules/@umijs/renderer-react';
import { context } from './helmetContext';

export const innerProvider = (container) => {
  return React.createElement(HelmetProvider, { context }, container);
}
