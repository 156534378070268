export const APPLIANCE_OPTIONS = [
  { label: '无', value: '0' },
  { label: '压力脊', value: '1' },
  { label: '颌垫', value: '2' },
  { label: '假牙', value: '3' },
  { label: '前导', value: '4' },
  { label: '咬合导板', value: '5' },
  { label: '牵引钩', value: '8' },
  { label: '牵引扣', value: '6' },
  { label: '引力扣', value: '9' },
  { label: '长臂钩附件', value: '7' },
];
export const TRACTION_OPTIONS = [
  { label: '牵引钩', value: '1' },
  { label: '牵引扣', value: '2' },
  { label: '引力扣', value: '3' },
  { label: '长臂钩附件', value: '5' },
];
export const judePos = ({
  upper,
  side,
  typename,
  mesiodistalDirection,
  direction,
  quadrant,
  styles,
  antStyles,
}: any) => {
  let vertical = null;
  let horizontal = null;
  let deg = null;
  const HL_DICT: any = {
    1: styles.right,
    2: styles.mid,
    3: styles.left,
  };
  const HR_DICT: any = {
    1: styles.left,
    2: styles.mid,
    3: styles.right,
  };
  const _class = [];
  switch (typename) {
    case '压力脊':
      if (upper) {
        vertical = side === '1' ? styles.top : styles.bottom;
      } else {
        vertical = side === '2' ? styles.top : styles.bottom;
      }
      horizontal = styles.mid;
      break;
    case '颌垫':
      horizontal = antStyles.mid2;
      if ((upper && side === '1') || (!upper && side === '2')) {
        _class.push(antStyles.top2);
      } else {
        _class.push(antStyles.bottom2);
      }
      break;
    case '假牙':
      if (upper) {
        vertical = side === '1' ? styles.top : styles.bottom;
        deg = side === '1' ? styles.deg180 : null;
      } else {
        vertical = side === '2' ? styles.top : styles.bottom;
        deg = side === '2' ? styles.deg180 : null;
      }
      horizontal = styles.mid;
      break;
    case '前导':
      horizontal = quadrant === 'left' ? styles.left : styles.right;
      _class.push(antStyles.width4);
      if ((upper && side === '1') || (!upper && side === '2')) {
        _class.push(antStyles.top4);
      } else {
        _class.push(antStyles.bottom4);
      }
      break;
    case '咬合导板':
      if (upper) {
        vertical = side === '1' ? styles.top : styles.bottom;
        deg = side === '2' ? styles.deg180 : null;
      } else {
        vertical = side === '2' ? styles.top : styles.bottom;
        deg = side === '1' ? styles.deg180 : null;
      }
      horizontal = styles.mid;
      break;
    case '牵引钩':
      if (upper) {
        vertical = side === '1' ? styles.top : styles.bottom;
      } else {
        vertical = side === '2' ? styles.top : styles.bottom;
      }
      horizontal =
        quadrant === 'left'
          ? HL_DICT[mesiodistalDirection]
          : HR_DICT[mesiodistalDirection];
      deg = direction === '2' ? styles.deg90 : null;
      break;
    case '牵引扣':
      if (upper) {
        vertical = side === '1' ? styles.top : styles.bottom;
        deg = side === '1' ? styles.deg180 : null;
      } else {
        vertical = side === '2' ? styles.top : styles.bottom;
        deg = side === '2' ? styles.deg180 : null;
      }
      horizontal = styles.mid;
      break;
    case '引力扣':
      vertical = styles.midy;
      if (upper) {
        deg = side === '2' ? styles.deg180 : null;
      } else {
        deg = side === '1' ? styles.deg180 : null;
      }
      horizontal =
        quadrant === 'left'
          ? HL_DICT[mesiodistalDirection]
          : HR_DICT[mesiodistalDirection];
      break;
    case '长臂钩附件':
      if (upper) {
        vertical = side === '1' ? styles.top : styles.bottom;
        deg = side === '2' ? styles.deg180 : null;
      } else {
        vertical = side === '2' ? styles.top : styles.bottom;
        deg = side === '1' ? styles.deg180 : null;
      }
      horizontal = styles.mid;
      break;
  }
  return [
    typename === '颌垫' ? styles['box-icon-hd'] : styles['box-icon'],
    ..._class,
    vertical,
    horizontal,
    deg,
  ]
    .filter(Boolean)
    .join(' ');
};
