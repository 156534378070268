import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Spin, theme } from 'antd';
import { OpsBtnsProps } from '../../types';
import { dropdownMenuStyle, opsBtnLinkStyle, opsBtnStyle } from './index.style';

/**
 * 📦 内置组件 - 隐力科技
 * @name OPS按钮组
 * @package @/aismile-atom
 * @author xuguanqun
 */
const OpsBtns: React.FC<OpsBtnsProps> = ({
  data = [],
  ops,
  position,
  selectedRowKeys,
  selectedRows,
  create,
  detail,
  modify,
  remove,
  batchRemove,
  export: _export,
  exportLoading,
  prefixCls,
  tableData,
  opsColProps,
}) => {
  /**
   * @name ops
   */
  const {
    detailPrefix = 'detail',
    createPrefix = 'create',
    modifyPrefix = 'modify',
    removePrefix = 'remove',
    batchRemovePrefix = 'batchRemove',
    exportPrefix = 'export',
    btns,
  } = ops || {};
  /**
   * @name opsColProps
   */
  const {
    text,
    record,
    index: parentIndex,
    action,
    schema,
  } = opsColProps || {};
  /**
   * @name theme
   */
  const {
    token: { colorPrimary },
  } = theme.useToken();
  /**
   * @name 生成按钮状态
   */
  const renderBtnProps = (
    item: Record<string, any>,
    btnDict: Record<string, any>,
    tableData: Record<string, any>,
  ) => {
    const {
      show: _show,
      loading: _loading,
      disabled: _disabled,
      danger: _danger,
      batch,
      ...props
    } = btnDict || {};
    let show = true;
    let loading = false;
    let disabled = false;
    let danger = false;
    // show
    if (typeof _show === 'function') {
      show = _show(record, parentIndex, item, tableData);
    } else if (typeof _show === 'boolean') {
      show = _show;
    }
    // loading
    if (typeof _loading === 'function') {
      loading = _loading(record, parentIndex, item, tableData);
    } else if (typeof _loading === 'boolean') {
      loading = _loading;
    }
    // disabled
    if (typeof _disabled === 'function') {
      disabled = _disabled(record, parentIndex, item, tableData);
    } else if (typeof _disabled === 'boolean') {
      disabled = _disabled;
    } else if (!_disabled && batch) {
      // batch 默认禁用逻辑
      disabled = !selectedRowKeys?.length;
    } else if (!_disabled && item.path === batchRemovePrefix) {
      // 批量删除 默认禁用逻辑
      disabled = !selectedRowKeys?.length;
    }
    // danger
    if (typeof _danger === 'function') {
      danger = _danger(record, parentIndex, item, tableData);
    } else if (typeof _danger === 'boolean') {
      danger = _danger;
    } else if (item?.path === removePrefix) {
      danger = true;
    }
    return { show, loading, disabled, danger, ...props };
  };
  /**
   * @name onClick
   */
  const _onClick = (path: string, index: number) => {
    const opsBtnConfig = btns?.[path];
    if (opsBtnConfig?.beforeClick) {
      let beforeClickRes = null;
      if (position === 'tableHeader') {
        beforeClickRes = opsBtnConfig.beforeClick(
          selectedRowKeys,
          selectedRows,
          index,
        );
      } else if (position === 'tableAction') {
        beforeClickRes = opsBtnConfig.beforeClick(
          text,
          record,
          parentIndex,
          action,
          schema,
        );
      }
      if (!beforeClickRes) {
        return null;
      }
    }

    if (opsBtnConfig?.onClick) {
      if (position === 'tableHeader') {
        opsBtnConfig.onClick(selectedRowKeys, selectedRows, index);
      } else if (position === 'tableAction') {
        opsBtnConfig.onClick(text, record, parentIndex, action, schema);
      }
    }
    // 默认curd方法
    else if (path === detailPrefix && detail) {
      detail(record);
    } else if (path === createPrefix && create) {
      create();
    } else if (path === modifyPrefix && modify) {
      modify(record);
    } else if (path === removePrefix && remove) {
      remove(record);
    } else if (path === batchRemovePrefix && batchRemove) {
      batchRemove();
    } else if (path === exportPrefix && _export) {
      _export();
    }
  };
  const btnType: any = { tableHeader: 'primary', tableAction: 'link' };
  const splitMaxFont = 8;
  let startSplitIndex = 0;
  const fontCount = data?.reduce((count: any, next: any, index: number) => {
    const nextLength = next?.name?.length || 0;
    const total = count + nextLength;
    if (total > splitMaxFont && startSplitIndex === 0) {
      startSplitIndex = index;
    }
    return total;
  }, 0);
  const enableSplit = fontCount > splitMaxFont; // 是否分割收缩
  return position === 'tableAction' && enableSplit ? (
    <Dropdown
      menu={{
        className: dropdownMenuStyle(prefixCls, { colorPrimary }),
        items: data
          ?.map((item: Record<string, any>) => {
            const { id, name, key } = item;
            const BTN_DICT = ops?.btns?.[key] || {};
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { show, loading, onClick, ...props }: any = renderBtnProps(
              item,
              BTN_DICT,
              tableData,
            );
            return show
              ? {
                  key: id,
                  label: (
                    <>
                      {loading && (
                        <Spin
                          indicator={<LoadingOutlined />}
                          size="small"
                          style={{ marginRight: 3 }}
                        />
                      )}
                      {name}
                    </>
                  ),
                  ...props,
                }
              : null;
          })
          .filter(Boolean),
        onClick: (item) => {
          const index = data?.findIndex((f: any) => f.id === item.key);
          const { key } = data[index] || {};
          if (ops?.btns?.[key]?.loading) {
            return null;
          }
          _onClick(key, index);
        },
      }}
    >
      <Button className={opsBtnLinkStyle()} type="link">
        更多
        <DownOutlined style={{ marginLeft: 0, verticalAlign: 'middle' }} />
      </Button>
    </Dropdown>
  ) : (
    <Space>
      {data?.map((item: Record<string, any>, index: number) => {
        const { id, name, key } = item;
        const BTN_DICT = ops?.btns?.[key] || {};
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { renderBtn, show, loading, onClick, ...props }: any =
          renderBtnProps(item, BTN_DICT, tableData);
        const _loading = key === exportPrefix ? exportLoading : loading;
        return show ? (
          renderBtn ? (
            renderBtn(name, selectedRowKeys, selectedRows)
          ) : (
            <Button
              className={opsBtnStyle(btnType[position])}
              key={id}
              type={btnType[position]}
              loading={_loading}
              {...props}
              onClick={() => {
                _onClick(key, index);
              }}
            >
              {name}
              {BTN_DICT?.batch &&
                selectedRowKeys &&
                selectedRowKeys.length > 0 && (
                  <span className="ops-btn-number">
                    {selectedRowKeys?.length}
                  </span>
                )}
            </Button>
          )
        ) : null;
      })}
    </Space>
  );
};
export default OpsBtns;
