// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
// defineApp
export { defineApp } from './core/defineApp'
export type { RuntimeConfig } from './core/defineApp'
// plugins
export { Access, useAccess, useAccessMarkedRoutes } from '/root/workspace/aismile-compatible_zTYF/src/.umi-production/plugin-access';
export { Provider, useModel } from '/root/workspace/aismile-compatible_zTYF/src/.umi-production/plugin-model';
export { connectMaster, MicroAppLink } from '/root/workspace/aismile-compatible_zTYF/src/.umi-production/plugin-qiankun-slave';
export { useRequest, UseRequestProvider, request, getRequestInstance } from '/root/workspace/aismile-compatible_zTYF/src/.umi-production/plugin-request';
// plugins types.d.ts
export * from '/root/workspace/aismile-compatible_zTYF/src/.umi-production/plugin-access/types.d';
export * from '/root/workspace/aismile-compatible_zTYF/src/.umi-production/plugin-layout/types.d';
export * from '/root/workspace/aismile-compatible_zTYF/src/.umi-production/plugin-request/types.d';
// @umijs/renderer-*
export { createBrowserHistory, createHashHistory, createMemoryHistory, Helmet, HelmetProvider, createSearchParams, generatePath, matchPath, matchRoutes, Navigate, NavLink, Outlet, resolvePath, useLocation, useMatch, useNavigate, useOutlet, useOutletContext, useParams, useResolvedPath, useRoutes, useSearchParams, useAppData, useClientLoaderData, useLoaderData, useRouteProps, useSelectedRoutes, useServerLoaderData, renderClient, __getRoot, Link, useRouteData, __useFetcher, withRouter } from '/root/workspace/aismile-compatible_zTYF/node_modules/.pnpm/@umijs/renderer-react@4.4.6_react-dom@18.3.1+react@18.3.1/node_modules/@umijs/renderer-react';
export type { History, ClientLoader } from '/root/workspace/aismile-compatible_zTYF/node_modules/.pnpm/@umijs/renderer-react@4.4.6_react-dom@18.3.1+react@18.3.1/node_modules/@umijs/renderer-react'
// umi/client/client/plugin
export { ApplyPluginsType, PluginManager } from '/root/workspace/aismile-compatible_zTYF/node_modules/.pnpm/umi@4.4.6_889fec82a145cc061d786fa6f01b3d88/node_modules/umi/client/client/plugin.js';
export { history, createHistory } from './core/history';
export { terminal } from './core/terminal';
// react ssr
export const useServerInsertedHTML: Function = () => {};
